import { Outlet } from 'react-router-dom';

import { FiltersProvider } from 'commons/utils/hooks/useFilters';
import { GenericShipmentProvider } from 'commons/utils/hooks/useGenericShipment';

const OrdersContextProvider = () => {
  return (
    <GenericShipmentProvider>
      <FiltersProvider>
        <Outlet />
      </FiltersProvider>
    </GenericShipmentProvider>
  );
};

export default OrdersContextProvider;
