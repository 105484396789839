import { Suspense } from 'react';

import './index.scss';

import { QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from '@tiendanube/nexo';
import { render } from 'react-dom';

import { StoreProvider } from 'commons/utils/hooks/useStoreContext';
import queryClient from 'commons/utils/query';

import Loading from 'domains/widgets/loading';

import nexo from 'nexoClient';

import App from './App';

const rootElement = document.getElementById('root');
const element: any = (
  <ErrorBoundary nexo={nexo}>
    <Suspense fallback={<Loading />}>
      <QueryClientProvider client={queryClient}>
        <StoreProvider>
          <App />
        </StoreProvider>
      </QueryClientProvider>
    </Suspense>
  </ErrorBoundary>
);
render(element, rootElement);
