const handleForceDownload = (path: any) => {
  const a = document.createElement('a');
  a.style.display = 'none';

  a.href = path;
  document.body.appendChild(a);

  a.target = '_blank';

  a.click();

  document.body.removeChild(a);
};

export { handleForceDownload };
