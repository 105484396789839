import axiosApiInstance from '../axios/axiosApiInstance';
import { QuotationResponseDto } from '../types/quotation-response-dto';
import { ShippingDetailsResponseDto } from '../types/shipping-details/shipping-details-response-dto';

export const fetch = async (
  id: string
): Promise<ShippingDetailsResponseDto> => {
  const response = await axiosApiInstance.get(`/stores/orders/${id}`);

  return response?.data;
};

export const fetchQuotation = async (
  id: string
): Promise<QuotationResponseDto> => {
  const response = await axiosApiInstance.get(
    `/stores/orders/${id}/quotations`
  );

  return response?.data || [];
};

export const deleteOrder = async (orderId: string) => {
  const response = await axiosApiInstance.delete(`/stores/orders/${orderId}`);

  return response?.data;
};

export const patchQuotation = async (orderId: string, payload: string) => {
  const response = await axiosApiInstance.patch(
    `/stores/orders/${orderId}/quotation`,
    {
      deliveryOptionId: payload
    }
  );

  return response?.data;
};

export const markAsSent = async (orderId: string) => {
  const response = await axiosApiInstance.patch(`/stores/orders`, [
    {
      orderId: orderId,
      currentStatus: 'sent'
    }
  ]);

  return response?.data;
};
