/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';

import { format, lastDayOfMonth, parseISO } from 'date-fns';
import { ptBR, es } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Icon,
  Input,
  Link,
  Sidebar,
  Tag,
  Text,
  Title
} from '@nimbus-ds/components';
import { ChevronLeftIcon, PlusCircleIcon } from '@nimbus-ds/icons';
import { DataList } from '@nimbus-ds/patterns';

import { currency } from 'commons/utils';
import { SIDEMODALS } from 'commons/utils/constants';
import { useSidemodalsContext } from 'commons/utils/hooks/useModal';
import { useStoreContext } from 'commons/utils/hooks/useStoreContext';
import { useChargeHistory } from 'commons/utils/query/hooks';

const ChargeHistory: FC = () => {
  const { t } = useTranslation();
  const { dispatch: dispatchSidemodal } = useSidemodalsContext();
  const store = useStoreContext();

  const [date, setDate] = useState({
    startCreatedAt: format(new Date(), 'yyyy-MM-01'),
    endCreatedAt: format(lastDayOfMonth(new Date()), 'yyyy-MM-dd'),
    current: format(new Date(), 'yyyy-MM')
  });

  const { data, refetch, isLoading, isFetching, isSuccess } = useChargeHistory({
    startCreatedAt: date.startCreatedAt,
    endCreatedAt: date.endCreatedAt,
    limit: 10,
    offset: 0
  });

  const handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = event.target.value;

    const date = {
      startCreatedAt: format(parseISO(selectedDate), 'yyyy-MM-01'),
      endCreatedAt: format(
        lastDayOfMonth(parseISO(selectedDate)),
        'yyyy-MM-dd'
      ),
      current: format(parseISO(selectedDate), 'yyyy-MM')
    };

    setDate(date);
  };

  const handleStatusType = (type: string) => {
    switch (type) {
      case 'in-progress':
      case 'created':
        return 'warning';
      case 'paid':
        return 'success';
      default:
        return 'danger';
    }
  };

  const handleAddCredit = (): void => {
    dispatchSidemodal({
      type: 'CLOSE_MODAL',
      payload: SIDEMODALS.CHARGE_HISTORY
    });

    dispatchSidemodal({ type: 'OPEN_MODAL', payload: SIDEMODALS.ADDCREDIT });
  };

  const handleClose = (): void => {
    dispatchSidemodal({
      type: 'CLOSE_MODAL',
      payload: SIDEMODALS.CHARGE_HISTORY
    });
  };

  useEffect(() => {
    refetch();
  }, [date]);

  return (
    <Sidebar open={true} padding="none" maxWidth={{ xs: '100%', md: '375px' }}>
      <Sidebar.Header>
        <Box padding="4" paddingBottom="none">
          <Link as="a" onClick={handleClose} textDecoration="none">
            <Icon color="primary-textHigh" source={<ChevronLeftIcon />} />
          </Link>
          <Box mt="6" gap="6" display="flex" flexDirection="column">
            <Title fontWeight="bold">
              {t('pre-paid.label-charge-history')}
            </Title>
            <Input
              type="month"
              placeholder={t('pre-paid.label-filter-per-month')}
              lang={store.language}
              value={date?.current}
              onChange={handleChangeDate}
              max={format(new Date(), 'yyyy-MM')}
            />
          </Box>
        </Box>
      </Sidebar.Header>

      <Sidebar.Body>
        {isFetching || isLoading ? (
          <DataList>
            <DataList.Row>
              <Tag.Skeleton width="80%" />
              <Box display="flex" mt="2" width="100%">
                <Text.Skeleton width="20%" />
              </Box>
            </DataList.Row>
          </DataList>
        ) : (
          <>
            {isSuccess && Object.entries(data).length ? (
              Object.entries(data).map(([key, subject]: any, index: number) => (
                <Box key={index}>
                  <Box padding={'4'} backgroundColor={'neutral-surface'}>
                    {key === format(new Date(), 'yyyy-MM-dd')
                      ? 'Hoje'
                      : format(parseISO(key), 'dd LLLL', {
                          locale: store.language === 'pt-BR' ? ptBR : es
                        })}
                  </Box>
                  <DataList>
                    {subject?.map((item: any, counter: number) => (
                      <DataList.Row key={counter}>
                        <Tag appearance={handleStatusType(item.status)}>
                          {t(`pre-paid.status.${item.status}`)}
                        </Tag>
                        <Box display="flex" mt="2">
                          <Text color="neutral-textDisabled">
                            {format(parseISO(item.createdAt), 'h:mm')}
                          </Text>
                          <Box ml={'auto'}>
                            <Text>
                              {currency.format(
                                store.language,
                                store.currency,
                                item.amount
                              )}
                            </Text>
                          </Box>
                        </Box>
                      </DataList.Row>
                    ))}
                  </DataList>
                </Box>
              ))
            ) : (
              <Box padding="4" display="flex" gap="4" flexDirection="column">
                <Text>{t('pre-paid.label-no-charges')}</Text>
                <Button appearance="primary" onClick={handleAddCredit}>
                  <Icon source={<PlusCircleIcon color="white" />} />
                  {t('widgets.balance.buttons.add-credit')}
                </Button>
              </Box>
            )}
          </>
        )}
      </Sidebar.Body>
    </Sidebar>
  );
};

export default ChargeHistory;
