import {
  DeliveryOrderCreationType,
  DeliveryOrderDeliveryType,
  DeliveryOrderFreightType
} from '../types/commons';

export const NECorreioSedex = 'ne-correios-sedex';
export const NECorreioPAC = 'ne-correios-pac';
export const NECorreioMini = 'ne-correios-mini';
export const NEMandaeEconomico = 'ne-mandae-economico';
export const NEMandaeExpress = 'ne-mandae-express';

export const NEAndreaniDomicilio = 'ne-andreani-domicilio';
export const NEAndreaniSucursal = 'ne-andreani-sucursal';

export const NECorreoArgClasicoDomicilio = 'ne-correo-arg-clasico-domicilio';
export const NECorreoArgClasicoSucursal = 'ne-correo-arg-clasico-sucursal';
export const NECorreoArgExpresoDomicilio = 'ne-correo-arg-expreso-domicilio';
export const NECorreoArgExpresoSucursal = 'ne-correo-arg-expreso-sucursal';

export const NEJadLog = 'ne-jadlog-package';
export const NEJadLogCom = 'ne-jadlog-com';

export const NEEnviaEstafetaGround = 'ne-envia-estafeta-ground';
export const NEEnviaEstafetaExpress = 'ne-envia-estafeta-express';
export const NEEnviaFedexGround = 'ne-envia-fedex-ground';
export const NEEnviaFedexExpress = 'ne-envia-fedex-express';

const handleDeliveryOptionName = (code: string): string => {
  switch (code) {
    case NECorreioSedex:
      return 'Correios Sedex';
    case NECorreioPAC:
      return 'Correios PAC';
    case NECorreioMini:
      return 'Correios Mini';
    case NEMandaeEconomico:
      return 'Nuvem Envio Econômico';
    case NEMandaeExpress:
      return 'Nuvem Envio Rápido';
    case NECorreoArgClasicoDomicilio:
      return 'Correo Argentino Clásico a domicilio';
    case NECorreoArgClasicoSucursal:
      return 'Correo Argentino Clásico a sucursal';
    case NECorreoArgExpresoDomicilio:
      return 'Correo Argentino Expreso a domicilio';
    case NECorreoArgExpresoSucursal:
      return 'Correo Argentino Expreso a sucursal';
    case NEJadLog:
      return 'Jadlog Econômico';
    case NEJadLogCom:
      return 'Jadlog Rápido';
    case NEAndreaniDomicilio:
      return 'Andreani a domicilio';
    case NEAndreaniSucursal:
      return 'Andreani a sucursal';
    case NEEnviaEstafetaGround:
      return 'Estafeta Terrestre';
    case NEEnviaEstafetaExpress:
      return 'Estafeta Express';
    case NEEnviaFedexGround:
      return 'FedEx Nacional Económico';
    case NEEnviaFedexExpress:
      return 'FedEx Nacional Día Siguiente';
    default:
      return code;
  }
};

const handleDeliveryType = (
  creationType: DeliveryOrderCreationType,
  deliveryType: DeliveryOrderDeliveryType,
  freightType?: DeliveryOrderFreightType
) => {
  if (creationType === 'sale') {
    if (freightType) {
      if (
        freightType === DeliveryOrderFreightType.NuvemEnvio ||
        freightType === DeliveryOrderFreightType.NativeCorreios ||
        freightType === DeliveryOrderFreightType.Fallback
      ) {
        return 'online-store';
      }

      return freightType;
    }

    return 'online-store';
  }

  if (creationType === 'detached') {
    return deliveryType;
  }
};

export { handleDeliveryOptionName, handleDeliveryType };
