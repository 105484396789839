import dayjs from 'dayjs';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import 'dayjs/locale/es';
import 'dayjs/locale/pt-br';

import { ELocale } from './i18n.types';

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    ns: 'common',
    defaultNS: 'common',
    load: 'currentOnly',
    fallbackLng: ELocale.BRAZIL,
    supportedLngs: Object.values(ELocale),
    interpolation: {
      escapeValue: false,
      format: (value, format) => {
        return value instanceof Date ? dayjs(value).format(format) : value;
      }
    }
  });

i18n.on('languageChanged', function (lng: ELocale) {
  const locales = {
    'es-MX': 'es',
    'es-AR': 'es',
    'pt-BR': 'pt-br'
  };
  dayjs.locale(locales[lng] || 'pt-br');
});

i18n.services.pluralResolver.addRule(ELocale.BRAZIL, {
  numbers: [1, 2],
  plurals: (n: number) => {
    return Number(n !== 1);
  }
});

export default i18n;
