import { format, parseISO } from 'date-fns';

import axiosApiInstance from '../axios/axiosApiInstance';

type Params = {
  startCreatedAt: string;
  endCreatedAt: string;
  limit: number;
  offset: number;
};

export const fetchChargeHistory = async (params: Params) => {
  try {
    const query = Object.entries(params)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');

    const response = await axiosApiInstance.get(
      `statement/credit-recharges?${query}`
    );

    return response?.data.reduce((created: any, item: any) => {
      const date = format(parseISO(item.createdAt), 'yyyy-MM-dd');
      if (!created[date]) {
        created[date] = [];
      }
      created[date].push(item);

      return created;
    }, {});
  } catch (err: unknown) {
    throw err;
  }
};
