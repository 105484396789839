export enum ELocale {
  ARGENTINA = 'es-AR',
  BRAZIL = 'pt-BR',
  MEXICO = 'es-MX'
}

export enum ECountry {
  ARGENTINA = 'AR',
  BRAZIL = 'BR',
  MEXICO = 'MX'
}

export enum ECurrency {
  ARGENTINA = 'ARS',
  BRAZIL = 'BRL',
  MEXICO = 'MXN'
}

export interface Translation {
  [key: string]: string | Translation;
}

export interface DomainTranslations {
  name: string;
  languages: {
    [ELocale.ARGENTINA]: Translation;
    [ELocale.MEXICO]: Translation;
    [ELocale.BRAZIL]: Translation;
  };
}
