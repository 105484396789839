import { addDays, isAfter, subDays } from 'date-fns';

import { REQUEST_DATE_FORMAT } from 'commons/utils/constants';
import { formatDate } from 'commons/utils/format';
import { FilterPeriod, FilterPeriodType } from 'commons/utils/types/filters';

export type FilterState = {
  status: string[];
  method: string[];
  period: FilterPeriodType;
  page: number;
  isUsingFilters: boolean;
  order: string;
  search?: string;
  location?: string;
};

export type Action =
  | {
      type: 'CHANGE_CONDITIONS';
      payload: Pick<
        FilterState,
        'method' | 'period' | 'status' | 'isUsingFilters' | 'location'
      >;
    }
  | { type: 'REMOVE_STATUS'; payload: string }
  | { type: 'REMOVE_METHOD'; payload: string }
  | { type: 'REMOVE_DISTRIBUITION_CENTER' }
  | { type: 'REMOVE_START_CREATED_AT' }
  | { type: 'REMOVE_END_CREATED_AT' }
  | { type: 'REMOVE_FILTERS' }
  | { type: 'REMOVE_SEARCH' }
  | { type: 'SEARCH'; payload: string }
  | { type: 'CHANGE_ORDER' }
  | { type: 'CHANGE_PAGINATION'; payload: number };

const initialState: FilterState = {
  status: [],
  method: [],
  period: {
    startCreatedAt: '',
    endCreatedAt: ''
  },
  search: '',
  page: 1,
  order: 'desc',
  isUsingFilters: false
};

const filtersReducer = (state: FilterState, action: Action): FilterState => {
  switch (action.type) {
    case 'CHANGE_CONDITIONS':
      return { ...state, ...action.payload, page: 1 };

    case 'REMOVE_STATUS':
      return {
        ...state,
        status: state.status.filter((status) => status !== action.payload),
        page: 1
      };

    case 'REMOVE_METHOD':
      return {
        ...state,
        method: state.method.filter((method) => method !== action.payload),
        page: 1
      };

    case 'REMOVE_START_CREATED_AT':
      const startDate = formatDate(
        subDays(new Date(state.period.endCreatedAt), FilterPeriod.LAST_60_DAYS),
        REQUEST_DATE_FORMAT
      );

      return {
        ...state,
        period: { ...state?.period, startCreatedAt: startDate },
        page: 1
      };

    case 'REMOVE_END_CREATED_AT':
      const startAddedSixtyDays = addDays(
        new Date(state.period.startCreatedAt),
        FilterPeriod.LAST_60_DAYS
      );

      const endDate = isAfter(new Date(), startAddedSixtyDays)
        ? formatDate(startAddedSixtyDays, REQUEST_DATE_FORMAT)
        : formatDate(new Date(), REQUEST_DATE_FORMAT);

      return {
        ...state,
        period: { ...state?.period, endCreatedAt: endDate },
        page: 1
      };
    case 'CHANGE_ORDER':
      return {
        ...state,
        order: state.order === 'desc' ? 'asc' : 'desc',
        page: 1
      };

    case 'SEARCH':
      return { ...state, search: action.payload, page: 1 };

    case 'REMOVE_SEARCH':
      return { ...state, search: '', page: 1 };

    case 'REMOVE_DISTRIBUITION_CENTER':
      return { ...state, location: '', page: 1 };

    case 'CHANGE_PAGINATION':
      return { ...state, page: action.payload };

    case 'REMOVE_FILTERS':
      return {
        ...state,
        status: [],
        method: [],
        period: {
          startCreatedAt: '',
          endCreatedAt: ''
        },
        search: '',
        page: 1,
        isUsingFilters: false
      };

    default:
      return state;
  }
};

export { initialState, filtersReducer };
