import React from 'react';

import { BrowserRouter } from 'react-router-dom';

import { ROUTES } from './constants';
import PublicRoutes from './publicRoutes';

const Routes: React.FC = () => {
  return (
    <BrowserRouter basename={ROUTES.DEFAULT}>
      <PublicRoutes />
    </BrowserRouter>
  );
};

export default Routes;
