import axiosApiInstance from 'commons/utils/axios';

import { ExternalShipmentState } from '../hooks/reducer/external-shipments';
import { ExternalShipmentsQuotationRequestDto } from '../types/external-shipments-request-dto';
import {
  ExternalShipmentsQuotationResponseDto,
  ExternalShipmentsResponseDto
} from '../types/external-shipments-response-dto';

export const fetch = async (
  state: ExternalShipmentState
): Promise<ExternalShipmentsResponseDto> => {
  const response = await axiosApiInstance.get(
    `/stores/external-orders?limit=${state.limit}&offset=${state.page - 1}`
  );

  return {
    items: response?.data?.items,
    total: response?.data.total
  };
};

export const migrate = async (
  externalDeliveryOrderIds: string[]
): Promise<string[]> => {
  const response = await axiosApiInstance.post(
    `/stores/external-orders/migrate`,
    { externalDeliveryOrderIds }
  );

  return response.data;
};

export const fetchQuotation = async (
  externalDeliveryOrderId: string
): Promise<ExternalShipmentsQuotationResponseDto> => {
  const response = await axiosApiInstance.get(
    `/stores/external-orders/${externalDeliveryOrderId}/quotations`
  );

  return response.data;
};

export const postQuotation = async ({
  externalDeliveryOrderId,
  deliveryOptionId
}: ExternalShipmentsQuotationRequestDto): Promise<{
  deliveryOrderId: string;
}> => {
  const response = await axiosApiInstance.post(
    `/stores/external-orders/migrate-with-quotation-update`,
    {
      externalDeliveryOrderId,
      deliveryOptionId
    }
  );

  return response.data;
};
