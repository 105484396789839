import { ECurrency } from 'App/i18n/i18n.types';

const format = (isoCode: string, currency: string, money: number): any => {
  const iso = isoCode || 'pt-br';
  const setCurrency = currency || ECurrency.BRAZIL;

  return new Intl.NumberFormat(iso, {
    style: 'currency',
    currency: setCurrency
  }).format(money);
};

export { format };
