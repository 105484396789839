import { SIDEMODALS } from 'commons/utils/constants';

export type SidemodalState = {
  [SIDEMODALS.ADDCREDIT]: boolean;
  [SIDEMODALS.BILLING_ADJUSTS]: boolean;
  [SIDEMODALS.CANCELLATION_ORDERS]: boolean;
  [SIDEMODALS.CHARGE_HISTORY]: boolean;
  [SIDEMODALS.FILTERS]: boolean;
  [SIDEMODALS.FREIGHT_CALCULATOR]: boolean;
  [SIDEMODALS.FREIGHT_CALCULATOR_TABLE]: boolean;
  [SIDEMODALS.GENERATE_FILES]: boolean;
  [SIDEMODALS.GENERATE_ROMANEIO]: boolean;
  [SIDEMODALS.TUTORIALS_NUVEM_ENVIO]: boolean;
  [SIDEMODALS.OWN_CONTRACT_CORREIOS]: boolean;
  [SIDEMODALS.DETAILS_SHIPMENT_PRINT]: boolean;
  [SIDEMODALS.DETAILS_SHIPMENT_QUOTATION]: boolean;
  [SIDEMODALS.DETAILS_SHIPMENT_SIMULATE]: boolean;
  [SIDEMODALS.DETAILS_SHIPMENT_CANCELLATION_ORDER]: boolean;
  [SIDEMODALS.EXTERNAL_SHIPMENTS_QUOTATION]: boolean;
  [SIDEMODALS.WELCOME_GO_PLUS]: boolean;
  [SIDEMODALS.WELCOME_FIRST_SHIPMENT]: boolean;
  [SIDEMODALS.GENERATE_FILES_FROM_ORDERS]: boolean;
};

const initialState: SidemodalState = {
  [SIDEMODALS.ADDCREDIT]: false,
  [SIDEMODALS.BILLING_ADJUSTS]: false,
  [SIDEMODALS.CANCELLATION_ORDERS]: false,
  [SIDEMODALS.CHARGE_HISTORY]: false,
  [SIDEMODALS.FILTERS]: false,
  [SIDEMODALS.FREIGHT_CALCULATOR]: false,
  [SIDEMODALS.FREIGHT_CALCULATOR_TABLE]: false,
  [SIDEMODALS.GENERATE_FILES]: false,
  [SIDEMODALS.GENERATE_ROMANEIO]: false,
  [SIDEMODALS.TUTORIALS_NUVEM_ENVIO]: false,
  [SIDEMODALS.OWN_CONTRACT_CORREIOS]: false,
  [SIDEMODALS.DETAILS_SHIPMENT_PRINT]: false,
  [SIDEMODALS.DETAILS_SHIPMENT_QUOTATION]: false,
  [SIDEMODALS.DETAILS_SHIPMENT_SIMULATE]: false,
  [SIDEMODALS.DETAILS_SHIPMENT_CANCELLATION_ORDER]: false,
  [SIDEMODALS.EXTERNAL_SHIPMENTS_QUOTATION]: false,
  [SIDEMODALS.WELCOME_GO_PLUS]: false,
  [SIDEMODALS.WELCOME_FIRST_SHIPMENT]: false,
  [SIDEMODALS.GENERATE_FILES_FROM_ORDERS]: false
};

export type Action =
  | {
      type: 'OPEN_MODAL';
      payload: SIDEMODALS;
    }
  | { type: 'CLOSE_MODAL'; payload: SIDEMODALS };

const sidemodalsReducer = (
  state: SidemodalState,
  action: Action
): SidemodalState => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return { ...state, [action.payload]: true };

    case 'CLOSE_MODAL':
      return { ...state, [action.payload]: false };

    default:
      return state;
  }
};

export { initialState, sidemodalsReducer };
