import { getSessionToken, getStoreInfo } from '@tiendanube/nexo';
import axios from 'axios';

import { token } from 'commons/utils';

import nexo from 'nexoClient';

import { Define } from '../nexo/define';

import { APIError } from './axiosApiInstanceError';

const axiosApiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  validateStatus: (status: number) => status >= 200 && status < 400
});

const usingNexo = new Define();

axiosApiInstance.interceptors.request.use(
  async (config) => {
    const storeId = usingNexo.nexo
      ? (await getStoreInfo(nexo)).id
      : localStorage.getItem('storeId');

    const usingToken = usingNexo.nexo
      ? await getSessionToken(nexo)
      : await token.generate(storeId);

    const contentSecurityPolicy =
      process.env.REACT_APP_CURRENT_ENVIRONMENT === 'staging'
        ? 'frame-ancestors *.nubestaging.com'
        : 'frame-ancestors *.lojavirtualnuvem.com.br *.mitiendanube.com';

    if (usingToken && config.headers) {
      config.headers['Authorization'] = `Bearer ${usingToken}`;
      config.headers['Content-Security-Policy'] = contentSecurityPolicy;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use(
  (res) => res,
  (err) => {
    throw APIError.fromAxiosError(err);
  }
);

export default axiosApiInstance;
