import { GenericShipmentQuotationResponseDto } from 'commons/utils/types/genericShipmentQuotationResponseDto';
import { GenericShipmentRequestDto } from 'commons/utils/types/genericShipmentRequestDto';

export type GenericShipmentState = {
  boxes: GenericShipmentRequestDto.Box[];
  customer: GenericShipmentRequestDto.Customer;
  declaredValue: number;
  quotation?: {
    deliveryOptionId: string;
  };
  destination: GenericShipmentRequestDto.Address;
  sender: GenericShipmentRequestDto.Address;
  invoiceKey?: string;
  quotationsList?: GenericShipmentQuotationResponseDto;
  modalCancel?: boolean;
};

const initialState: GenericShipmentState = {
  boxes: [],
  customer: {
    name: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    legalIdentification: ''
  },
  destination: {
    address: '',
    city: '',
    country: '',
    name: '',
    neighborhood: '',
    number: '',
    province: '',
    zipCode: '',
    complement: ''
  },
  sender: {
    address: '',
    city: '',
    country: '',
    name: '',
    neighborhood: '',
    number: '',
    province: '',
    zipCode: '',
    complement: ''
  },
  quotation: { deliveryOptionId: '' },
  invoiceKey: '',
  declaredValue: 0,
  quotationsList: {
    active: [],
    disabled: []
  },
  modalCancel: false
};

export type Action =
  | {
      type: 'SUBMIT_FORM';
      payload: GenericShipmentRequestDto;
    }
  | {
      type: 'LOAD_DELIVERY_OPTIONS';
      payload: GenericShipmentQuotationResponseDto;
    }
  | {
      type: 'SELECT_DELIVERY_OPTION';
      payload: string;
    }
  | { type: 'CLEAR_FORM' }
  | {
      type: 'OPEN_MODAL';
    }
  | {
      type: 'CLOSE_MODAL';
    };

const genericShipmentReducer = (
  state: GenericShipmentState,
  action: Action
): GenericShipmentState => {
  switch (action.type) {
    case 'SUBMIT_FORM':
      return { ...state, ...action.payload };

    case 'LOAD_DELIVERY_OPTIONS':
      return { ...state, quotationsList: action.payload };

    case 'SELECT_DELIVERY_OPTION':
      return {
        ...state,
        quotation: {
          deliveryOptionId: action.payload
        }
      };

    case 'CLEAR_FORM':
      return initialState;

    case 'OPEN_MODAL':
      return { ...state, modalCancel: true };

    case 'CLOSE_MODAL':
      return { ...state, modalCancel: false };

    default:
      return state;
  }
};

export { initialState, genericShipmentReducer };
