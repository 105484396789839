import '@nimbus-ds/styles/dist/index.css';
import { FC } from 'react';

import { ToastProvider } from '@nimbus-ds/components';

import Appcues from 'commons/utils/appcues/appcues';
import { SidemodalsProvider } from 'commons/utils/hooks/useModal';

import Sidemodals from 'domains/widgets/sidemodals';

import Routes from './Routes/Router';

const App: FC = () => {
  return (
    <SidemodalsProvider>
      <ToastProvider>
        <Appcues />

        <Routes />
        <Sidemodals />
      </ToastProvider>
    </SidemodalsProvider>
  );
};

export default App;
