import React, { useReducer, createContext, useContext } from 'react';

import { ProviderProps } from '../types/provider';

import {
  initialState,
  FilterState,
  Action,
  filtersReducer
} from './reducer/filter';

type FilterContextType = {
  state: FilterState;
  dispatch: React.Dispatch<Action>;
};

const FilterContext = createContext<FilterContextType | undefined>(undefined);

const useFilters = (): FilterContextType => {
  const [state, dispatch] = useReducer(filtersReducer, initialState);

  return { state, dispatch };
};

const useFiltersContext = (): FilterContextType => {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error('useFiltersContext must be used within a FilterProvider');
  }

  return context;
};

const FiltersProvider: React.FC<ProviderProps> = ({ children }) => {
  const filters = useFilters();
  return (
    <FilterContext.Provider value={filters}>{children}</FilterContext.Provider>
  );
};

export { useFiltersContext, FiltersProvider };
