import { useState } from 'react';

import { getStoreInfo } from '@tiendanube/nexo';
import { useTranslation } from 'react-i18next';

import { useToast } from '@nimbus-ds/components';

import { addCredit } from 'commons/utils/query/add-credit';

import nexo from 'nexoClient';

import { AMP_BUTTON, sendAmplitudeData } from '../amplitude';
import { IS_PRODUCTION, TOAST_DURATION_IN_MS } from '../constants';
import { useBalance } from '../query/hooks';
import { storeDomain } from '../services';

const useAddCredit = () => {
  const { refetch } = useBalance();
  const { addToast } = useToast();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const handleAddingCredit = async (value: number) => {
    try {
      setIsLoading(true);

      const response = await addCredit(value);
      const url = response.checkoutUrl;

      if (url.includes('/admin/account')) {
        const urlBuilt = await buildUrl(url);
        window.open(urlBuilt);

        sendAmplitudeData(AMP_BUTTON, {
          action: 'Billing - Top up - Go to payment'
        });
      } else {
        window.open(url);
      }
      refetch();
    } catch (error) {
      addToast({
        id: 'error-add-credit',
        type: 'danger',
        text: t('widgets.add-credit.texts.error-payment'),
        duration: TOAST_DURATION_IN_MS
      });
    } finally {
      setIsLoading(false);
    }
  };

  const buildUrl = async (url: string) => {
    if (IS_PRODUCTION) {
      const storeInfo = await getStoreInfo(nexo);
      return storeInfo.url + url;
    }

    const store = await storeDomain();

    return store.domain + url;
  };

  return { handleAddingCredit, isLoading };
};

export default useAddCredit;
