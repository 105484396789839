import React, { useReducer, createContext, useContext } from 'react';

import { ProviderProps } from '../types/provider';

import {
  initialState,
  DashboardState,
  Action,
  dashboardReducer
} from './reducer/dashboard';

type DashboardContextType = {
  state: DashboardState;
  dispatch: React.Dispatch<Action>;
};

const DashboardContext = createContext<DashboardContextType | undefined>(
  undefined
);

const useDashboard = (): DashboardContextType => {
  const [state, dispatch] = useReducer(dashboardReducer, initialState);

  return { state, dispatch };
};

const useDashboardContext = (): DashboardContextType => {
  const context = useContext(DashboardContext);

  if (!context) {
    throw new Error(
      'useDashboardContext must be used within a DashboardProvider'
    );
  }

  return context;
};

const DashboardProvider: React.FC<ProviderProps> = ({ children }) => {
  const dashboard = useDashboard();
  return (
    <DashboardContext.Provider value={dashboard}>
      {children}
    </DashboardContext.Provider>
  );
};

export { useDashboardContext, DashboardProvider };
