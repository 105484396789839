import axiosApiInstance from 'commons/utils/axios';

export const addCredit = async (
  amount: number
): Promise<{ checkoutUrl: string }> => {
  const response = await axiosApiInstance.post('/statement/credit-recharges', {
    amount
  });

  return response?.data;
};
