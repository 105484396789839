import React, { useReducer, createContext, useContext } from 'react';

import { ProviderProps } from '../types/provider';

import {
  initialState,
  GenericShipmentState,
  Action,
  genericShipmentReducer
} from './reducer/generic-shipment';

type GenericShipmentContextType = {
  state: GenericShipmentState;
  dispatch: React.Dispatch<Action>;
};

const GenericShipmentContext = createContext<
  GenericShipmentContextType | undefined
>(undefined);

const useGenericShipment = (): GenericShipmentContextType => {
  const [state, dispatch] = useReducer(genericShipmentReducer, initialState);

  return { state, dispatch };
};

const useGenericShipmentContext = (): GenericShipmentContextType => {
  const context = useContext(GenericShipmentContext);

  if (!context) {
    throw new Error(
      'useGenericShipmentContext must be used within a GenericShipmentProvider'
    );
  }

  return context;
};

const GenericShipmentProvider: React.FC<ProviderProps> = ({ children }) => {
  const generic = useGenericShipment();
  return (
    <GenericShipmentContext.Provider value={generic}>
      {children}
    </GenericShipmentContext.Provider>
  );
};

export { useGenericShipmentContext, GenericShipmentProvider };
