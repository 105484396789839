import { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';

import {
  Box,
  Button,
  Icon,
  Input,
  Link,
  Radio,
  Sidebar,
  Spinner,
  Text,
  Title
} from '@nimbus-ds/components';
import { ChevronLeftIcon } from '@nimbus-ds/icons';

import { currency } from 'commons/utils';
import {
  AR_MINIMUM_AMOUNT_PRE_PAID,
  BR_MINIMUM_AMOUNT_PRE_PAID,
  SIDEMODALS
} from 'commons/utils/constants';
import maskToCurrency from 'commons/utils/format/mask-currency';
import useAddCredit from 'commons/utils/hooks/useAddCredit';
import { useSidemodalsContext } from 'commons/utils/hooks/useModal';
import { useStoreContext } from 'commons/utils/hooks/useStoreContext';
import queryClient from 'commons/utils/query';

import { ECountry } from '../../../App/i18n/i18n.types';

const AR_DEFAULT_VALUES: string[] = [];
const BR_DEFAULT_VALUES: string[] = ['10,00', '50,00', '100,00'];

const AddCredit: FC = () => {
  const { t } = useTranslation();
  const store = useStoreContext();
  const { dispatch } = useSidemodalsContext();

  const [defaultValues, setDefaultValues] = useState(BR_DEFAULT_VALUES);

  const [minimumAmountPrepaid, setMinimumAmountPrepaid] = useState(
    BR_MINIMUM_AMOUNT_PRE_PAID
  );

  const suggestionRecharge = queryClient.getQueryData<{ amount: number }>([
    'statement-suggestion'
  ]);

  const [value, setValue] = useState<string>('');
  const [minimumAmountError, setMinimumAmountError] = useState<boolean>(false);

  const { handleAddingCredit, isLoading } = useAddCredit();

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace('.', '');
    const amount = value ? parseFloat(value.replace(',', '.')) : 0;

    if (amount < minimumAmountPrepaid) {
      setMinimumAmountError(true);
    } else {
      setMinimumAmountError(false);
    }
    setValue(event.target.value);
  };

  const handleClickDefaultOption = (
    event: React.SyntheticEvent<EventTarget>
  ) => {
    const element = (event.currentTarget as Element).getAttribute('id');
    const increase = element || '';

    setValue(increase);
  };

  const handleConfirmCharge = async () => {
    const amount = value.replace('.', '').replace(',', '.');

    handleAddingCredit(parseFloat(amount));
  };

  const handleClose = (): void => {
    setValue('');

    dispatch({ type: 'CLOSE_MODAL', payload: SIDEMODALS.ADDCREDIT });
  };

  useEffect(() => {
    if (suggestionRecharge?.amount) {
      setValue(
        suggestionRecharge.amount.toFixed(2).toString().replace('.', ',')
      );
      queryClient.resetQueries(['statement-suggestion']);
    }
  }, [suggestionRecharge]);

  useEffect(() => {
    if (ECountry.ARGENTINA === store.country) {
      setDefaultValues(AR_DEFAULT_VALUES);
      setMinimumAmountPrepaid(AR_MINIMUM_AMOUNT_PRE_PAID);
    }
  }, [store]);

  return (
    <Sidebar
      padding="base"
      position="right"
      open={true}
      zIndex="800"
      onRemove={handleClose}
      maxWidth={{ xs: '100%', md: '375px' }}
    >
      <Sidebar.Header>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap="6"
          justifyContent="flex-start"
        >
          <Link as="a" onClick={handleClose} textDecoration="none">
            <Icon color="primary-textHigh" source={<ChevronLeftIcon />} />
          </Link>

          <Title>{t('widgets.add-credit.title')}</Title>
        </Box>
      </Sidebar.Header>
      <Sidebar.Body>
        <Box mt="6" display="flex" flexDirection="column" gap="2">
          <InputMask
            mask="9999999999"
            alwaysShowMask={false}
            beforeMaskedStateChange={maskToCurrency}
            onChange={handleChangeInput}
            value={value}
            maskPlaceholder={''}
          >
            <Input
              appendPosition="start"
              append={t('widgets.add-credit.placeholders.currency')}
              name="value"
              appearance={minimumAmountError ? 'danger' : 'neutral'}
              placeholder={t('widgets.add-credit.placeholders.input')}
            />
          </InputMask>

          {minimumAmountError && (
            <Text fontSize="caption" color="danger-interactive">
              {t('widgets.add-credit.labels.minimum-amount')}
            </Text>
          )}
        </Box>

        <Box display="flex" gap="2" mt="4">
          {defaultValues.map((item: string, index: number) => (
            <Radio
              key={index}
              onClick={handleClickDefaultOption}
              name="default-values"
              id={`${item}`}
              as="button"
              label={currency.format(
                store.language,
                store?.currency,
                parseFloat(item)
              )}
              checked={value === parseFloat(item).toFixed(2)}
              readOnly
            />
          ))}
        </Box>

        <Box
          display="flex"
          justifyContent="flex-start"
          gridGap="2-5"
          width="100%"
          mt="16"
        >
          <Box display="flex" gap="2">
            <Button onClick={handleClose}>
              {t('widgets.add-credit.buttons.cancel')}
            </Button>
            <Button
              appearance="primary"
              onClick={handleConfirmCharge}
              disabled={isLoading || minimumAmountError || !value}
            >
              {isLoading && <Spinner color="currentColor" size="small" />}
              {t('widgets.add-credit.buttons.proceed')}
            </Button>
          </Box>
        </Box>
      </Sidebar.Body>
    </Sidebar>
  );
};

export default AddCredit;
