import { Buffer } from 'buffer';

import * as jose from 'jose';

const generate = async (storeId: any) => {
  const aws = process.env.REACT_APP_SECRET_KEY || '';

  if (storeId) {
    const jwt = await new jose.SignJWT({ storeId })
      .setProtectedHeader({ alg: 'HS256' })
      .setExpirationTime('2h')
      .sign(Buffer.from(aws));

    return jwt;
  }

  return false;
};

export { generate };
