import { FC, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, Button, Icon, Link, Text, Title } from '@nimbus-ds/components';
import { PlusCircleIcon } from '@nimbus-ds/icons';

import { currency } from 'commons/utils';
import { AMP_BUTTON, sendAmplitudeData } from 'commons/utils/amplitude';
import { SIDEMODALS } from 'commons/utils/constants';
import { useSidemodalsContext } from 'commons/utils/hooks/useModal';
import { useStoreContext } from 'commons/utils/hooks/useStoreContext';
import { useBalance } from 'commons/utils/query/hooks/balance';

import { OnboardingAddCredit } from 'domains/widgets/onboarding/';

interface BalanceProps {
  displayLinkToShowStatement: boolean;
  page: 'billing' | 'shipment';
  isDoingOnboarding: boolean;
}

const Balance: FC<BalanceProps> = ({
  displayLinkToShowStatement,
  page,
  isDoingOnboarding
}) => {
  const { t } = useTranslation();
  const { dispatch: dispatchSidemodal } = useSidemodalsContext();
  const store = useStoreContext();

  const { data, isFetching, refetch } = useBalance();

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleOpenModal = () => {
    dispatchSidemodal({
      type: 'OPEN_MODAL',
      payload: SIDEMODALS.ADDCREDIT
    });

    sendAmplitudeData(AMP_BUTTON, {
      action: `${page.toLocaleUpperCase()} - Top up - Add`
    });
  };

  return (
    <Box display="flex" gap="4" flexDirection="column">
      <Box
        borderColor="neutral-surfaceHighlight"
        backgroundColor="transparent"
        display="flex"
        borderStyle="solid"
        borderWidth="1"
        borderRadius="2"
        padding={{ xs: '4', md: '6' }}
        width={{ xs: '100%' }}
        alignItems={{
          xs: 'flex-start',
          md: 'center'
        }}
        flexDirection={{
          xs: 'column',
          md: 'row'
        }}
      >
        <Box width="50%" display="flex" flexDirection="column" gap="1">
          <Text fontSize="base" role="complementary">
            {t('widgets.balance.labels.available-balance')}
          </Text>
          {!data && isFetching && <Text.Skeleton />}
          {data && !isFetching && (
            <Title fontWeight="bold" lineHeight="6" as="h2" role="text">
              {currency.format(
                store.language,
                store.currency,
                data?.currentBalance
              )}
            </Title>
          )}
        </Box>
        <Box
          ml="auto"
          display="flex"
          gap="4"
          width={{ xs: '100%', md: 'auto' }}
          mt={{ xs: '4', md: 'none' }}
          alignItems="center"
        >
          <Box order={{ xs: '1', md: '2' }}>
            {isDoingOnboarding ? (
              <OnboardingAddCredit />
            ) : (
              <Button appearance="primary" onClick={handleOpenModal}>
                <Icon source={<PlusCircleIcon color="white" />} />
                {t('widgets.balance.buttons.add-credit')}
              </Button>
            )}
          </Box>

          {displayLinkToShowStatement && (
            <Box order={{ xs: '2', md: '1' }}>
              <Link
                as={'a'}
                appearance="primary"
                textDecoration="none"
                href="/billing"
              >
                {t('widgets.balance.buttons.show-statement')}
              </Link>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Balance;
