import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, Button, Icon, Link, Popover, Text } from '@nimbus-ds/components';
import { EllipsisIcon } from '@nimbus-ds/icons';

import { AMP_BUTTON, sendAmplitudeData } from 'commons/utils/amplitude';
import { useDashboardContext } from 'commons/utils/hooks/useDashboardContext';
import { useStoreContext } from 'commons/utils/hooks/useStoreContext';

interface OnboardingOrderActionProps {
  visible: boolean;
  callback: () => void;
}
const OnboardingTutorials: FC<OnboardingOrderActionProps> = ({
  visible,
  callback
}) => {
  const { t } = useTranslation();
  const { state, dispatch } = useDashboardContext();
  const store = useStoreContext();

  const handleGoToStep = (shouldAction: boolean) => {
    let amplitudeAction = 'Onboarding - highlight 3 - Next';
    const step = {
      step: 4,
      visible: false
    };

    dispatch({
      type: 'DEFINE_ONBOARDING',
      payload: step
    });

    if (shouldAction) {
      amplitudeAction = 'Onboarding - highlight 3 - Go to Action';
      callback();
    }

    sendAmplitudeData(AMP_BUTTON, {
      action: amplitudeAction
    });

    localStorage.setItem('onboarding', JSON.stringify(step));
  };

  return (
    <Popover
      content={
        <Box display="flex" flexDirection="column" width="280" gap="2" mt="1">
          <Text fontWeight="bold" color="neutral-background">
            {t('widgets.onboarding.step-3.title')}
          </Text>
          <Text color="neutral-background">
            {t('widgets.onboarding.step-3.text')}
          </Text>
          <Box ml="auto">
            <Link
              as="button"
              onClick={() => handleGoToStep(false)}
              appearance="neutral-background"
            >
              {t('widgets.onboarding.step-3.button')}
            </Link>
          </Box>
        </Box>
      }
      visible={
        visible &&
        state.onboarding?.visible &&
        state.onboarding.step === 3 &&
        !store.configuration.hasCreatedDispatch
      }
      backgroundColor="primary-interactiveHover"
      position="bottom-end"
      width="280px"
    >
      <Box
        display={{ xs: 'flex', md: 'none' }}
        justifyContent="center"
        width="30px"
        onClick={() => handleGoToStep(true)}
        my="2"
      >
        <Icon
          color="neutral-textLow"
          source={<EllipsisIcon />}
          onClick={() => handleGoToStep(true)}
        />
      </Box>
      <Box
        display={{ xs: 'none', md: 'flex' }}
        onClick={() => handleGoToStep(true)}
      >
        <Button appearance="neutral">
          {t('dashboard.buttons.more-options')}
          <Icon
            color="neutral-textLow"
            source={<EllipsisIcon />}
            onClick={() => handleGoToStep(true)}
          />
        </Button>
      </Box>
    </Popover>
  );
};

export default OnboardingTutorials;
