import { ECurrency } from 'App/i18n/i18n.types';

export class Define {
  static isNexo = false;
  static language = 'pt-BR';
  static currency = ECurrency.BRAZIL;

  public set nexo(value: boolean) {
    Define.isNexo = value;
  }

  public get nexo() {
    return Define.isNexo;
  }

  set language(value: string) {
    Define.language = value;
  }

  get language() {
    return Define.language;
  }

  set currency(value: ECurrency) {
    Define.currency = value;
  }

  get currency() {
    return Define.currency;
  }
}
