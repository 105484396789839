import React, { useReducer, createContext, useContext } from 'react';

import { migrate } from '../query/external-shipments';
import { ProviderProps } from '../types/provider';

import {
  initialState,
  ExternalShipmentState,
  Action,
  externalShipmentReducer
} from './reducer/external-shipments';

type ExternalShipmentContextType = {
  state: ExternalShipmentState;
  dispatch: React.Dispatch<Action>;
};

const ExternalShipmentsContext = createContext<
  ExternalShipmentContextType | undefined
>(undefined);

const useExternalShipments = (): ExternalShipmentContextType => {
  const [state, dispatch] = useReducer(externalShipmentReducer, initialState);

  return { state, dispatch };
};

const useExternalShipmentsContext = (): ExternalShipmentContextType => {
  const context = useContext(ExternalShipmentsContext);

  if (!context) {
    throw new Error(
      'useExternalShipmentsContext must be used within a ExternalShipmentsProvider'
    );
  }

  return context;
};

const ExternalShipmentsProvider: React.FC<ProviderProps> = ({ children }) => {
  const externalShipments = useExternalShipments();

  return (
    <ExternalShipmentsContext.Provider value={externalShipments}>
      {children}
    </ExternalShipmentsContext.Provider>
  );
};

type UseExternalShipmentsActionsType = {
  handleMigrate: (externalDeliveryOrders: string[]) => Promise<any>;
  handleChangeQuotation: () => Promise<void>;
};

export const useExternalShipmentsActions =
  (): UseExternalShipmentsActionsType => {
    const handleMigrate = async (externalDeliveryOrders: string[]) => {
      return await migrate(externalDeliveryOrders);
    };

    const handleChangeQuotation = async () => {
      return;
    };

    return {
      handleMigrate,
      handleChangeQuotation
    } as const;
  };

export { useExternalShipmentsContext, ExternalShipmentsProvider };
