import { useEffect } from 'react';

import {
  ACTION_NAVIGATE_SYNC,
  NavigateSyncResponse,
  syncPathname
} from '@tiendanube/nexo';
import { useLocation, useNavigate } from 'react-router-dom';

import nexo from 'nexoClient';

import { useStoreContext } from '../hooks/useStoreContext';

function NexoSyncRoute({ children }: { children: JSX.Element }) {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const store = useStoreContext();

  useEffect(() => {
    const path = search ? `${pathname}${search}` : pathname;
    syncPathname(nexo, path);
  }, [pathname, search]);

  useEffect(() => {
    const unsuscribe = nexo.suscribe(
      ACTION_NAVIGATE_SYNC,
      ({ path, replace }: NavigateSyncResponse) => {
        navigate(path, { replace });
      }
    );

    return unsuscribe;
  }, [navigate]);

  if (store.loading) return null;

  return children;
}

export default NexoSyncRoute;
