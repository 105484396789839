import amplitude from 'amplitude-js';

import { IS_PRODUCTION } from '../constants';

export const initAmplitude = () => {
  if (process.env.REACT_APP_AMPLITUDE) {
    amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE);
  }
};

export const setAmplitudeUserDevice = (installationToken: any) => {
  if (IS_PRODUCTION) {
    amplitude.getInstance().setDeviceId(installationToken);
  }
};

export const setAmplitudeUserId = (userId: any) => {
  if (IS_PRODUCTION) {
    amplitude.getInstance().setUserId(userId);
  }
};

export const setAmplitudeUserProperties = (properties: any) => {
  if (IS_PRODUCTION) {
    amplitude.getInstance().setUserProperties(properties);
  }
};

export const sendAmplitudeData = (eventType: any, eventProperties: any) => {
  if (IS_PRODUCTION) {
    amplitude.getInstance().logEvent(eventType, eventProperties);
  }
};
