import {
  GetPaginatedStatementsApiResponse,
  PaginatedStatements,
  StatementsByDate
} from 'domains/billing/pre-paid/wallet-statement/wallet-statement.types';

import axiosApiInstance from '../axios/axiosApiInstance';
import { groupStatementsByDate } from '../format';
import { GetCycleByPeriodResultDto } from '../types/billing/get-cycle-by-period-result-dto';

import queryClient from '.';

export const fetch = async (id: string, params: any) => {
  try {
    const response = await axiosApiInstance.get(
      `/billing/cycles/${id}/accounting-entries?${
        params ? params : ''
      }&accountingEntryTypes=deliveryOrder`
    );
    return response?.data;
  } catch (err: unknown) {
    throw err;
  }
};

export const fetchCycles = async (params: string) => {
  try {
    const response = await axiosApiInstance.get(
      `/billing/cycles${params ? params : ''}`
    );
    return response?.data;
  } catch (err: unknown) {
    throw err;
  }
};

export const fetchCycleID = async (id: string) => {
  if (id) {
    const response = await axiosApiInstance.get(`/billing/cycles/${id}`);
    return response?.data || [];
  }
};

export const fetchAdjustsByCycleId = async (id: string, params: any) => {
  if (id) {
    const response = await axiosApiInstance.get(
      `/billing/cycles/${id}/accounting-entries?limit=${params?.limit}&offset=${params?.offset}&accountingEntryTypes=${params?.type}`
    );
    return response?.data;
  }
};

export const fetchPrepaidLabelsReport = async (
  param: string
): Promise<GetCycleByPeriodResultDto> => {
  const response = await axiosApiInstance.get(
    `/billing/cycles/summary?${param}`
  );

  return response?.data;
};

export const fetchPrepaidWalletReport = async (
  params: string
): Promise<GetPaginatedStatementsApiResponse> => {
  let query = params;

  if (query.includes('&creditDebit=all')) {
    query = query.replace('&creditDebit=all', '');
  }
  const response =
    await axiosApiInstance.get<GetPaginatedStatementsApiResponse>(
      `/statement?${query}`
    );

  return {
    total: response?.data?.total,
    statements: response?.data?.statements
  };
};

export const fetchPrepaidWalletReportDesktop = async (
  params: string
): Promise<PaginatedStatements> => {
  const response = await fetchPrepaidWalletReport(params);
  return {
    total: response.total,
    statements: groupStatementsByDate(response.statements)
  };
};

export const fetchPrepaidWalletReportMobile = async (
  params: string
): Promise<PaginatedStatements> => {
  const currentState = queryClient.getQueryData<StatementsByDate>([
    'billing-prepaid-wallet-report'
  ]);

  const response = await fetchPrepaidWalletReport(params);

  const allStatements = [
    ...Object.values(currentState?.statements ?? {}).flat(),
    ...response.statements
  ];

  return {
    total: response.total,
    statements: groupStatementsByDate(allStatements)
  };
};

export const fetchStatementSimulate = async (orderIds: string[]) => {
  const response = await axiosApiInstance.post(
    `/statement/simulate-dispatch-charge`,
    { orderIds }
  );

  return response?.data;
};
