import { ErrorType } from 'commons/utils/errors/error-type';
import { ShippingDetailsResponseDto } from 'commons/utils/types/shipping-details/shipping-details-response-dto';

type Error = {
  type: ErrorType | null;
  order?: ShippingDetailsResponseDto;
  values?: {
    [k: string]: string | number | undefined;
  };
};

export type ShippingDetailsState = {
  error: Error | undefined;
};

export type Action =
  | { type: 'SET_ERROR'; payload: Error }
  | { type: 'CLEAR_ERROR' };

const initialState: ShippingDetailsState = {
  error: undefined
};

const shippingDetailsReducer = (
  state: ShippingDetailsState,
  action: Action
): ShippingDetailsState => {
  switch (action.type) {
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload
      };

    case 'CLEAR_ERROR':
      return {
        ...state,
        error: undefined
      };

    default:
      return state;
  }
};

export { initialState, shippingDetailsReducer };
