import { PERPAGE_EXTERNAL_SHIPMENTS } from 'commons/utils/constants';

export type ExternalShipmentState = {
  page: number;
  limit: number;
  externalDeliveryOrders?: string[];
};

export type Action =
  | { type: 'CHANGE_PAGINATION'; payload: number }
  | { type: 'SET_DELIVERY_ORDERS'; payload: string[] };

const initialState: ExternalShipmentState = {
  page: 1,
  limit: PERPAGE_EXTERNAL_SHIPMENTS,
  externalDeliveryOrders: []
};

const externalShipmentReducer = (
  state: ExternalShipmentState,
  action: Action
): ExternalShipmentState => {
  switch (action.type) {
    case 'CHANGE_PAGINATION':
      return { ...state, page: action.payload };

    case 'SET_DELIVERY_ORDERS':
      return { ...state, externalDeliveryOrders: action.payload };

    default:
      return state;
  }
};

export { initialState, externalShipmentReducer };
