import { handleDeliveryOptionName } from '../freight-type/freight-type';

const generate = (text: string, error: any) => {
  let currentText: string = text;

  const replaceList = [
    'dispatcher-gw.error.dispatcher-not-found-error',
    'dispatch-gw.error.unexpected-dispatcher-error',
    'dispatch-gw.error.bigger-than-max-width',
    'dispatch-gw.error.bigger-than-max-height',
    'dispatch-gw.error.bigger-than-max-depth',
    'dispatch-gw.error.bigger-than-max-weight',
    'dispatch-gw.error.required-delivery-order-property-is-undefined',
    'dispatch-gw.error.bigger-than-max-dimensions-sum',
    'dispatch-gw.cancel.error.dispatch-order-already-cancelled'
  ];

  if (replaceList.includes(error?.code)) {
    if (currentText.includes('this.carrierCode')) {
      currentText = currentText.replace(
        'this.carrierCode',
        error?.values?.carrierCode
      );
    }

    if (currentText.includes('this.deliveryOptionCode')) {
      currentText = currentText.replace(
        'this.deliveryOptionCode',
        handleDeliveryOptionName(error?.values?.deliveryOptionCode)
      );
    }

    if (currentText.includes('this.width')) {
      currentText = currentText.replace(
        'this.width',
        `${error?.values?.width}cm`
      );
    }

    if (currentText.includes('this.maxWidth')) {
      currentText = currentText.replace(
        'this.maxWidth',
        `${error?.values?.maxWidth}cm`
      );
    }

    if (currentText.includes('this.height')) {
      currentText = currentText.replace(
        'this.height',
        `${error?.values?.height}cm`
      );
    }

    if (currentText.includes('this.maxHeight')) {
      currentText = currentText.replace(
        'this.maxHeight',
        `${error?.values?.maxHeight}cm`
      );
    }

    if (currentText.includes('this.depth')) {
      currentText = currentText.replace(
        'this.depth',
        `${error?.values?.depth}cm`
      );
    }

    if (currentText.includes('this.maxDepth')) {
      currentText = currentText.replace(
        'this.maxDepth',
        `${error?.values?.maxDepth}cm`
      );
    }

    if (currentText.includes('this.property')) {
      currentText = currentText.replace(
        'this.property',
        error?.values?.property
      );
    }

    if (currentText.includes('this.dimensionsSum')) {
      currentText = currentText.replace(
        'this.dimensionsSum',
        error?.values?.dimensionsSum
      );
    }

    if (currentText.includes('this.maxDimensionsSum')) {
      currentText = currentText.replace(
        'this.maxDimensionsSum',
        error?.values?.maxDimensionsSum
      );
    }

    if (currentText.includes('this.weight')) {
      currentText = currentText.replace(
        'this.weight',
        `${error?.values?.weight}kg`
      );
    }

    if (currentText.includes('this.maxWeight')) {
      currentText = currentText.replace(
        'this.maxWeight',
        `${error?.values?.maxWeight}kg`
      );
    }

    if (currentText.includes('this.deliveryOrderId')) {
      currentText = currentText.replace(
        'this.deliveryOrderId',
        error?.values?.deliveryOrderId
      );
    }
  }

  return currentText;
};

export { generate };
