import { useQuery } from '@tanstack/react-query';

import { fetch, fetchQuotation } from '../shipping-details';

export const useShippingDetails = (id: string, enabled?: boolean) => {
  return useQuery(['shipping-details', id], () => fetch(id), {
    enabled: enabled && !!id
  });
};

export const useQuotation = (orderId: string) => {
  return useQuery(['quotation', orderId], () => fetchQuotation(orderId), {
    enabled: !!orderId
  });
};
