import { FC } from 'react';

import { useSidemodalsContext } from 'commons/utils/hooks/useModal';

import { ChargeHistory } from 'domains/balance';

import AddCredit from '../add-credit';
import CanalNuvemEnvio from '../canal-nuvem-envio';

const Sidemodals: FC = () => {
  const { state } = useSidemodalsContext();

  return (
    <>
      {state.addCredit && <AddCredit />}

      {state.chargeHistory && <ChargeHistory />}

      {state.tutorialsNuvemEnvio && <CanalNuvemEnvio />}
    </>
  );
};

export default Sidemodals;
