import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Box,
  Icon,
  Link,
  Sidebar,
  Thumbnail,
  Title
} from '@nimbus-ds/components';
import { ChevronLeftIcon } from '@nimbus-ds/icons';
import { DataList } from '@nimbus-ds/patterns';

import { AMP_BUTTON, sendAmplitudeData } from 'commons/utils/amplitude';
import { SIDEMODALS } from 'commons/utils/constants';
import { useSidemodalsContext } from 'commons/utils/hooks/useModal';

const MenuItems: FC = () => {
  const { t } = useTranslation();

  const { dispatch: dispatchSidemodal } = useSidemodalsContext();

  const videos = [
    {
      id: 'jxzA_MbHnic',
      title: t('widgets.canal-nuvem-envio.video-7'),
      index: 7
    },
    {
      id: 'U707M358X1o',
      title: t('widgets.canal-nuvem-envio.video-8'),
      index: 8
    },
    {
      id: 'DedQOWi7pOw',
      title: t('widgets.canal-nuvem-envio.video-9'),
      index: 9
    },
    {
      id: 'BvjcpPfsEL0',
      title: t('widgets.canal-nuvem-envio.video-1'),
      index: 1
    },
    {
      id: 'X1n86d9fdps',
      title: t('widgets.canal-nuvem-envio.video-2'),
      index: 2
    },
    {
      id: 'UD_lZQOIb_8',
      title: t('widgets.canal-nuvem-envio.video-10'),
      index: 10
    },
    {
      id: 'z_205c0ShKQ',
      title: t('widgets.canal-nuvem-envio.video-4'),
      index: 4
    },
    {
      id: 'YxQ0V2EDtVc',
      title: t('widgets.canal-nuvem-envio.video-5'),
      index: 5
    }
  ];

  const handleAmplitude = (index: number) => {
    sendAmplitudeData(AMP_BUTTON, { action: `Watch video ${index + 1}` });
  };

  const handleClose = (): void => {
    dispatchSidemodal({
      type: 'CLOSE_MODAL',
      payload: SIDEMODALS.TUTORIALS_NUVEM_ENVIO
    });
  };

  return (
    <Sidebar
      padding="none"
      position="right"
      open={true}
      zIndex="900"
      onRemove={handleClose}
      maxWidth={{ xs: '100%', md: '375px' }}
    >
      <Sidebar.Header padding="base">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap="6"
          justifyContent="flex-start"
        >
          <Link as="a" onClick={handleClose} textDecoration="none">
            <Icon color="primary-textHigh" source={<ChevronLeftIcon />} />
          </Link>

          <Title>{t('widgets.canal-nuvem-envio.title')}</Title>
        </Box>
      </Sidebar.Header>
      <Sidebar.Body padding="none">
        <DataList>
          {videos?.map((item, index) => (
            <DataList.Row key={index} id={`video-${item.index}`} gap="1">
              <Box display="flex" gap="4">
                <Box width="70px">
                  <Thumbnail
                    src={`https://img.youtube.com/vi/${item.id}/1.jpg`}
                    alt=""
                    width="55"
                    height="42"
                  />
                </Box>
                <Box width="70%">
                  <Link
                    href={`https://www.youtube.com/watch?v=${item.id}`}
                    target="_blank"
                    appearance="primary"
                    onClick={() => handleAmplitude(item.index)}
                    textDecoration="none"
                    as="a"
                  >
                    {item.title}
                  </Link>
                </Box>
              </Box>
            </DataList.Row>
          ))}
        </DataList>
      </Sidebar.Body>
    </Sidebar>
  );
};

export default MenuItems;
