import React, { useReducer, createContext, useContext, useEffect } from 'react';

import {
  ACTION_LOG_ERROR,
  connect,
  getStoreInfo,
  iAmReady
} from '@tiendanube/nexo';
import { ECountry, ECurrency, ELocale } from 'App/i18n/i18n.types';
import { useTranslation } from 'react-i18next';

import { token } from 'commons/utils';

import nexo from 'nexoClient';

import { initAmplitude, setAmplitudeUserId } from '../amplitude';
import { Define } from '../nexo/define';
import { fetch as fetchConfigs } from '../query/configurations';
import { fetch } from '../query/store';
import { ProviderProps } from '../types/provider';

import { initialState, StoresState, storeReducer } from './reducer/stores';
import '../../../App/i18n';

const StoreContext = createContext<StoresState | undefined>(undefined);

const useStoreContext = (): StoresState => {
  const context = useContext(StoreContext);

  if (!context) {
    throw new Error('useStoreContext must be used within a StoreProvider');
  }

  return context;
};

initAmplitude();

const StoreProvider: React.FC<ProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(storeReducer, initialState);

  const { i18n } = useTranslation();

  const query = new URLSearchParams(window.location.search);

  const usingNexo = new Define();

  const getCountryByMerchant = (country: string) => {
    switch (country.toLowerCase()) {
      case 'argentina':
        return ECountry.ARGENTINA;
      case 'brasil':
        return ECountry.BRAZIL;
      case 'mexico':
        return ECountry.MEXICO;
    }
  };

  const getCurrencyByMerchant = (country: ECountry) => {
    switch (country) {
      case ECountry.ARGENTINA:
        return ECurrency.ARGENTINA;
      case ECountry.BRAZIL:
        return ECurrency.BRAZIL;
      case ECountry.MEXICO:
        return ECurrency.MEXICO;
    }
  };

  const getLanguageByMerchant = (country: ECountry) => {
    switch (country) {
      case ECountry.ARGENTINA:
        return ELocale.ARGENTINA;
      case ECountry.BRAZIL:
        return ELocale.BRAZIL;
      case ECountry.MEXICO:
        return ELocale.MEXICO;
    }
  };

  useEffect(() => {
    connect(nexo)
      .then(async () => {
        const storeInfo = await getStoreInfo(nexo);
        const lang = `${storeInfo?.language}-${storeInfo?.country}`;
        iAmReady(nexo);
        i18n.changeLanguage(lang);

        setAmplitudeUserId(storeInfo.id);
        usingNexo.nexo = true;

        const storesResponse = await fetch();
        const configsResponse = await fetchConfigs();

        dispatch({
          type: 'SET_DEFINITIONS',
          payload: {
            loading: false,
            language: `${storeInfo?.language}-${storeInfo?.country}`,
            currency: getCurrencyByMerchant(storeInfo.country as ECountry),
            country: storeInfo?.country,
            ...storesResponse,
            configuration: configsResponse
          }
        });
      })
      .catch(async () => {
        // eslint-disable-next-line no-console
        console.log('nexo nao responde');
        nexo.dispatch({ type: ACTION_LOG_ERROR });

        if (process.env.REACT_APP_CURRENT_ENVIRONMENT === 'staging') {
          const storeId =
            query.get('store_id') || localStorage.getItem('storeId');
          const jwt = await token.generate(storeId);

          setAmplitudeUserId(query.get('store_id'));

          localStorage.setItem('token', jwt || '');
          localStorage.setItem('auth', 'true');
          localStorage.setItem('storeId', storeId || '');

          const storesResponse = await fetch();
          const configsResponse = await fetchConfigs();

          const country = storesResponse?.address?.country
            ? getCountryByMerchant(storesResponse?.address?.country)
            : ECountry.BRAZIL;

          dispatch({
            type: 'SET_DEFINITIONS',
            payload: {
              loading: false,
              language: getLanguageByMerchant(country as ECountry),
              currency: getCurrencyByMerchant(country as ECountry),
              ...storesResponse,
              configuration: configsResponse,
              country
            }
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StoreContext.Provider value={state}>{children}</StoreContext.Provider>
  );
};

export { useStoreContext, StoreProvider, StoreContext };
