import { IApiError } from './axiosApiInstance.types';

export class APIError implements IApiError {
  status: number | undefined;
  message?: string | null | undefined;
  errors?: string | [] | null | undefined;

  constructor(message: string, status: number | undefined, errors: any) {
    this.message = message;
    this.status = status;
    this.errors = errors;
  }

  public static fromAxiosError(error: any) {
    const parse = { ...error };

    if (parse?.response === undefined) {
      return new APIError('error-generic', 500, [{ code: 'error-generic' }]);
    }
    return new APIError(
      error.response?.data?.message || error.response?.statusText,
      error.response?.status,
      error.response?.data?.errors
    );
  }
}
