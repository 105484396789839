import { FC } from 'react';

import { Spinner } from '@nimbus-ds/components';

import './loading.styled.scss';

const Loading: FC = () => {
  return (
    <section id="loading">
      <Spinner color="primary-interactive" size="medium" />
    </section>
  );
};

export default Loading;
