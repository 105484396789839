import React, { useReducer, createContext, useContext } from 'react';

import { ProviderProps } from '../types/provider';

import {
  initialState,
  SidemodalState,
  Action,
  sidemodalsReducer
} from './reducer/sidemodals';

type SidemodalContextType = {
  state: SidemodalState;
  dispatch: React.Dispatch<Action>;
};

const SidemodalContext = createContext<SidemodalContextType | undefined>(
  undefined
);

const useSidemodal = (): SidemodalContextType => {
  const [state, dispatch] = useReducer(sidemodalsReducer, initialState);

  return { state, dispatch };
};

const useSidemodalsContext = (): SidemodalContextType => {
  const context = useContext(SidemodalContext);

  if (!context) {
    throw new Error(
      'useSidemodalsContext must be used within a SidemodalsProvider'
    );
  }

  return context;
};

const SidemodalsProvider: React.FC<ProviderProps> = ({ children }) => {
  const sidemodals = useSidemodal();
  return (
    <SidemodalContext.Provider value={sidemodals}>
      {children}
    </SidemodalContext.Provider>
  );
};

export { useSidemodalsContext, SidemodalsProvider };
