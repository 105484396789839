export type FilterStatusType = {
  waiting: boolean;
  sent: boolean;
  cancelled: boolean;
};

export type FilterFreightType = {
  name: string;
  label: string;
  checked: boolean;
};

export enum FilterOrder {
  ASC = 'asc',
  DESC = 'desc'
}

export type FilterPeriodType = {
  startCreatedAt: string;
  endCreatedAt: string;
  type?: string;
};

export enum FilterPeriod {
  LASTDAY = 1,
  LAST_7_DAYS = 7,
  LAST_30_DAYS = 30,
  LAST_60_DAYS = 60
}
