import { QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      retry: 0
    }
  }
});

queryClient.setQueryData(['errors-query'], { visible: false, list: [] });

export default queryClient;
