import axiosApiInstance from '../axios/axiosApiInstance';
import { ConfigurationsMandaeResponseDto } from '../types/configurations/configurations-mandae-response-dto';
import {
  ConfigurationsCorreiosContractRequestDto,
  ConfigurationsRequestDto
} from '../types/configurations/configurations-request-dto';
import {
  ConfigurationsCorreiosContractResponseDto,
  ConfigurationsResponseDto
} from '../types/configurations/configurations-response-dto';

export const fetch = async (): Promise<ConfigurationsResponseDto> => {
  try {
    const response = await axiosApiInstance.get(`stores/configs`);

    return response.data;
  } catch (err: unknown) {
    throw err;
  }
};

export const patch = async (
  payload: ConfigurationsRequestDto
): Promise<ConfigurationsResponseDto> => {
  try {
    const response = await axiosApiInstance.patch(`stores/configs`, payload);

    return response?.data;
  } catch (err: unknown) {
    throw err;
  }
};

export const setupD2D = async (): Promise<ConfigurationsMandaeResponseDto> => {
  const response = await axiosApiInstance.post('auth/mandae-setup');

  return response?.data;
};

export const setupCorreiosContract = async (
  payload: ConfigurationsCorreiosContractRequestDto
): Promise<ConfigurationsCorreiosContractResponseDto> => {
  try {
    const response = await axiosApiInstance.put(
      `stores/config/correios/contract`,
      payload
    );

    return response?.data;
  } catch (err: unknown) {
    throw err;
  }
};
