export const IS_PRODUCTION =
  process.env.REACT_APP_CURRENT_ENVIRONMENT === 'production';

export const PERPAGE = 50;
export const PERPAGE_EXTERNAL_SHIPMENTS = 50;
export const PERPAGE_WALLET_REPORT = 100;

export const OTHER_REASON_MIN_LENGTH = 4;
export const BR_MINIMUM_AMOUNT_PRE_PAID = 4;
export const AR_MINIMUM_AMOUNT_PRE_PAID = 50;

export const TOAST_DURATION_IN_MS = 8000;
export const MASSIVE_STATUS = 'massiveStatus';
export const ACTION_REPRINT_DOCUMENTS = 'reprint-documents';
export const SAIBA_MAIS_LINK =
  'https://atendimento.nuvemshop.com.br/pt_BR/nuvem-envio/como-pagar-pelos-meus-envios-atraves-do-nuvem-envio';
export const BR_LANDING_PAGE = 'https://arc.net/l/quote/udibkbek';

export const AR_LANDING_PAGE =
  'https://ayuda.tiendanube.com/es_ES/envio-nube/que-es-envio-nube-y-como-activarlo-en-mi-tienda';

export const VIDEO_ALLOW_POPUP = 'https://youtu.be/YxQ0V2EDtVc?feature=shared';
export const DISPLAY_FRIENDLY_DATE = 'dd-MM-yyyy';
export const REQUEST_DATE_FORMAT = 'yyyy-MM-dd';
export const APPCUESID = 126721;
export const HELP_OWN_CONTRACT_LINK =
  'https://static.helpjuice.com/helpjuice_production/uploads/upload/image/7634/3791781/Criação_da_senhas_novas_apis_Correios_240124_172652.pdf';

export const ZIPCODE_MASK = '99999-999';
export const CPF_MASK = '999.999.999-999';
export const CNPJ_MASK = '99.999.999/9999-99';
export const NFE_MASK =
  '9999 9999 9999 9999 9999 9999 9999 9999 9999 9999 9999';
export const PHONE_MASK = '(99) 99999-9999';
export const HEIGHT_MASK = '999';
export const DEPTH_MASK = '999';
export const WIDTH_MASK = '999';
export const WEIGHT_MASK = '999999';
export const CURRENCY_MASK = '9999999999';

export const KEYCODE_ENTER = 'enter';

export const PARTNER_PEGAKI_MAPS =
  'https://locationsmap.pegaki.com.br/pontos/11934';

export const PARTNER_JADLOG_MAPS =
  'https://www.jadlog.com.br/siteInstitucional/pickup.jad';
export const PARTNER_JADLOG_TRACKING = 'https://jadlog.com.br/tracking?cte=';
export const PARTNER_JADLOG_LANDING_PAGE =
  'https://atendimento.nuvemshop.com.br/pt_BR/nuvem-envio/como-funciona-o-nuvem-envio-jadlog-com-pontos-de-postagem';

export const ALLOWED_ARGENTINA_STORES_SINGLE_SHIPMENT_STAGING = [
  '3765071',
  '3765320',
  '3765617',
  '3765617',
  '3765385',
  '3766469',
  '3766495',
  '3766514',
  '3766680',
  '3766729',
  '3767263',
  '3769906',
  '3769923',
  '3767387',
  '3767394',
  '3767656',
  '3767820',
  '3768410',
  '3768982'
];

export const ALLOWED_ARGENTINA_STORES_SINGLE_SHIPMENT_PRODUCTION = [
  '2859303',
  '3856301',
  '3796591',
  '3592341',
  '689548',
  '3953437'
];

export enum SIDEMODALS {
  ADDCREDIT = 'addCredit',
  BILLING_ADJUSTS = 'billingAdjusts',
  CANCELLATION_ORDERS = 'cancellationOrders',
  CHARGE_HISTORY = 'chargeHistory',
  FILTERS = 'filters',
  FREIGHT_CALCULATOR = 'freightCalculator',
  FREIGHT_CALCULATOR_TABLE = 'freightCalculatorTable',
  GENERATE_FILES = 'generateFiles',
  GENERATE_ROMANEIO = 'generateRomaneio',
  TUTORIALS_NUVEM_ENVIO = 'tutorialsNuvemEnvio',
  OWN_CONTRACT_CORREIOS = 'ownContractCorreios',
  DETAILS_SHIPMENT_SIMULATE = 'simulatePrePaid',
  DETAILS_SHIPMENT_PRINT = 'printFiles',
  DETAILS_SHIPMENT_QUOTATION = 'quotation',
  DETAILS_SHIPMENT_CANCELLATION_ORDER = 'cancellationOrder',
  EXTERNAL_SHIPMENTS_QUOTATION = 'externalShipmentsQuotation',
  WELCOME_GO_PLUS = 'welcomeGoPlus',
  WELCOME_FIRST_SHIPMENT = 'welcomeFirstShipment',
  GENERATE_FILES_FROM_ORDERS = 'generateFilesFromOrders'
}

export enum BANNERS {
  GOPLUS = 'show-go-plus-banner',
  JADLOG = 'show-jadlog-activation-banner'
}

export enum CorreiosContract {
  NE = 'NE',
  BALCAO = 'Balcao',
  OWN_CONTRACT = 'Proprio'
}

export const BREAKPOINTS = {
  xs: 0,
  md: 672,
  lg: 1024
};
