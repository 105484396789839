import { Outlet } from 'react-router-dom';

import { ShippingDetailsErrorProvider } from 'commons/utils/hooks/useShippingDetailsErrorContext';

const ShippingDetailsContextProvider = () => {
  return (
    <ShippingDetailsErrorProvider>
      <Outlet />
    </ShippingDetailsErrorProvider>
  );
};

export default ShippingDetailsContextProvider;
