export enum CorreiosContract {
  NE = 'NE',
  BALCAO = 'Balcao',
  OWN_CONTRACT = 'Proprio'
}

export enum CorreiosContractLevel {
  Bronze = 'Bronze',
  Silver = 'Silver',
  Gold = 'Gold',
  Platinum = 'Platinum',
  Diamond = 'Diamond',
  Infinite = 'Infinite',
  Club = 'Club',
  Encomenda0 = 'Encomenda0',
  Encomenda1 = 'Encomenda1',
  Encomenda2 = 'Encomenda2',
  Encomenda3 = 'Encomenda3',
  Encomenda4 = 'Encomenda4',
  Encomenda5 = 'Encomenda5',
  Encomenda6 = 'Encomenda6',
  Encomenda7 = 'Encomenda7',
  Encomenda8 = 'Encomenda8',
  Encomenda9 = 'Encomenda9',
  Unidentified = 'Unidentified'
}
