/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable @typescript-eslint/no-namespace */

import { NuvemEnvioCategory } from './category';
import { CorreiosContractLevel } from './correios-contract';

type ContractErrorItem = {
  code: string;
  template: string;
  default: string;
};

export type ConfigurationsCorreiosContractResponseDto = {
  userCredential: string;
  password: string;
  postalCard: string;
  directoryNumber: string;
  contractLevel: string;
  contractNumber: string;
};

export type ConfigurationsCorreiosContractErrorResponseDto = {
  status: number;
  message: string;
  timestamp: string;
  errors: ContractErrorItem[];
};

export type ConfigurationsResponseDto = {
  correiosConfig: ConfigurationsResponseDto.CorreiosConfig;
  displayReadyToPackDeliveries: boolean;
  displayOnlyPaidOrders: boolean;
  mandaeConfig: ConfigurationsResponseDto.MandaeConfig;
  hasLocations: boolean;
  showGoPlusBanner: boolean;
  hasFinancialIssues?: boolean;
  hasAdminBlocked: boolean;
  hasCreatedDispatch: boolean;
  showJadlogActivationBanner: boolean;
};

export namespace ConfigurationsResponseDto {
  export type CorreiosConfig = {
    carrierCode: string;
    contractType: string;
    deliveryNotice: boolean;
    enabled: boolean;
    insurance: boolean;
    selectPackageReceiver: boolean;
    configs?: {
      postalCard: string;
      contractLevel: CorreiosContractLevel;
      contractNumber: string;
      userCredential: string;
      directoryNumber: number;
    } | null;
  };

  export type MandaeConfig = {
    category: NuvemEnvioCategory;
    d2dConnected: boolean;
    declaredQuotationValue: boolean;
    declaredShippingValue: boolean;
  };
}

export const GetByCorreiosContractLevel: Record<CorreiosContractLevel, string> =
  {
    [CorreiosContractLevel.Bronze]: 'Bronze',
    [CorreiosContractLevel.Silver]: 'Prata',
    [CorreiosContractLevel.Gold]: 'Ouro',
    [CorreiosContractLevel.Platinum]: 'Platina',
    [CorreiosContractLevel.Diamond]: 'Diamante',
    [CorreiosContractLevel.Infinite]: 'Infinito',
    [CorreiosContractLevel.Club]: 'Clube',
    [CorreiosContractLevel.Encomenda0]: 'Encomenda 0',
    [CorreiosContractLevel.Encomenda1]: 'Encomenda 1',
    [CorreiosContractLevel.Encomenda2]: 'Encomenda 2',
    [CorreiosContractLevel.Encomenda3]: 'Encomenda 3',
    [CorreiosContractLevel.Encomenda4]: 'Encomenda 4',
    [CorreiosContractLevel.Encomenda5]: 'Encomenda 5',
    [CorreiosContractLevel.Encomenda6]: 'Encomenda 6',
    [CorreiosContractLevel.Encomenda7]: 'Encomenda 7',
    [CorreiosContractLevel.Encomenda8]: 'Encomenda 8',
    [CorreiosContractLevel.Encomenda9]: 'Encomenda 9',
    [CorreiosContractLevel.Unidentified]: 'Não identificado'
  };
