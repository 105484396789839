export const ROUTES = {
  DEFAULT: '/',
  ERROR: '/error',
  DASHBOARD: '/dashboard',
  SHIPPINGDETAILS: '/shipping-details/:id',
  BILLING: '/billing',
  BILLINGDETAILS: '/billing/details/:id',
  CONFIGURATIONS: '/configurations',
  CONFIGURATION_PREFERENCES: '/configurations/preferences',
  SINGLE_SHIPMENT: '/single-shipment',
  SINGLE_SHIPMENT_QUOTATION: '/single-shipment/quotation',
  REVERSE_SHIPMENT: '/reverse-shipment',
  REVERSE_SHIPMENT_QUOTATION: '/reverse-shipment/quotation',
  HEALTH: '/health',
  AUTH: '/auth'
};
