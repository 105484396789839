import { ECountry } from 'App/i18n/i18n.types';

import { ConfigurationsResponseDto } from './configurations/configurations-response-dto';
import { StoresResponseDto } from './stores-response-dto';

export enum BillingType {
  PREPAID = 'prepaid',
  POSTPAID = 'postpaid'
}

export type ActionStoreReducerType = {
  loading: boolean;
  store?: StoresResponseDto;
  configuration?: ConfigurationsResponseDto;
  country?: ECountry;
  currency?: string;
  language?: string;
};

type StandardLanguageCode =
  | 'es_AR'
  | 'es_MX'
  | 'es_CL'
  | 'es_CO'
  | 'pt_BR'
  | 'en_US';

type Name = {
  [key in StandardLanguageCode]: string;
};

interface NameWithWildcard extends Name {
  '*': string;
}

export interface DistributionCenter {
  externalId: string;
  name: NameWithWildcard;
}

export type OriginAddress = {
  address?: string;
  number?: string;
  city?: string;
  province?: string;
  country?: string;
  zipCode: string;
  complement?: string | null;
  neighborhood?: string;
  locations?: DistributionCenter[];
};
