import { AxiosResponse } from 'axios';

import axiosApiInstance from 'commons/utils/axios';
import { IApiResponse } from 'commons/utils/axios/axiosApiInstance.types';

export const storeDomain = async (): Promise<any> => {
  try {
    const response = (await axiosApiInstance.get(
      `/stores/nuvemshop`
    )) as AxiosResponse<IApiResponse<any>>;
    return response?.data;
  } catch (err: unknown) {
    throw err;
  }
};
