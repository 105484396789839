import { useQuery } from '@tanstack/react-query';

import { fetch } from '../balance';
import { fetchStatementSimulate } from '../billing';

export const useBalance = () => {
  return useQuery(['balance'], () => fetch(), { enabled: false });
};

export const useStatementSimulate = (orders: string[]) => {
  return useQuery(
    ['statement-simulate'],
    () => fetchStatementSimulate(orders),
    { enabled: false }
  );
};
