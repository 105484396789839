import React, { useReducer, createContext, useContext } from 'react';

import { ProviderProps } from '../types/provider';

import {
  ShippingDetailsState,
  Action,
  initialState,
  shippingDetailsReducer
} from './reducer/shipping-details';

type ShippingDetailsErrorContextType = {
  state: ShippingDetailsState;
  dispatch: React.Dispatch<Action>;
};

const ShippingDetailsErrorContext = createContext<
  ShippingDetailsErrorContextType | undefined
>(undefined);

const useShippingDetailsError = (): ShippingDetailsErrorContextType => {
  const [state, dispatch] = useReducer(shippingDetailsReducer, initialState);

  return { state, dispatch };
};

const useShippingDetailsErrorContext = (): ShippingDetailsErrorContextType => {
  const context = useContext(ShippingDetailsErrorContext);

  if (!context) {
    throw new Error(
      'useShippingDetailsErrorContext must be used within a ShippingDetailsErrorProvider'
    );
  }

  return context;
};

const ShippingDetailsErrorProvider: React.FC<ProviderProps> = ({
  children
}) => {
  const errors = useShippingDetailsError();
  return (
    <ShippingDetailsErrorContext.Provider value={errors}>
      {children}
    </ShippingDetailsErrorContext.Provider>
  );
};

export { useShippingDetailsErrorContext, ShippingDetailsErrorProvider };
